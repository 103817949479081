@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.bg-esg-blue {
    background: #12344B;

}

.clr-esg-blue {
    color: #12344B;

}
.font-lato {
    font-family: 'Lato' !important;
}
.text-big-one {

 
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    leading-trim: both;

    text-edge: cap;
}

/* body, html {
    font-family: 'Lato', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: var(--gray-900, #101828);
} */
.p-tag-blue {
    background: #31597590;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 6px;
}

.clr-navy {

    color: var(--Navy, #315975);
}
.clr-navy-imp {

    color: var(--Navy, #315975) !important;
}

.bg-navy {

    background: var(--Navy, #315975);
}
.text-bold-500 {
    
    font-weight: 500;
}

.text-bold {
    
    font-weight: bold;
}

.fs-10 {
    
    font-size: 10px;
}

.fs-12 {
    
    font-size: 12px;
}

.fs-14 {
    
    font-size: 14px !important;
}

.fs-16 {
    
    font-size: 16px !important;
}

.fs-36 {
    
    font-size: 36px !important;
}
.fs-38 {
    
    font-size: 38px !important;
}
.fs-18 {
    
    font-size: 18px !important;
}
.fs-20 {
    
    font-size: 20px !important;
}
.fs-22 {
    
    font-size: 22px;
}
.fs-24 {
    
    font-size: 24px;
}
.fs-26 {
    
    font-size: 26px;
}
.lh-38{
    line-height: 38px;
}
.lh-20{
    line-height: 20px;
}
.lh-24{
    line-height: 20px;
}
.text-micro {
    
    color: var(--Gray-2, #4F4F4F);
    leading-trim: both;
    text-edge: cap;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;

}

.hover-blue:hover {
    background: linear-gradient(97deg, rgba(49,89,117,1) 0%, rgba(255,255,255,1) 100%);
    cursor: pointer;
    color: white;

    font-weight: bold;
}



.cur-pointer {
    cursor: pointer !important;
}

.bg-smoke {
    background-color: #F9F9F9;
}

.bg-white {
    background-color: white;
}

.bg-g5 {
    background-color: #E0E0E0;
}
.fw-7 {
    font-weight: 700;
}
.fw-6 {
    font-weight: 600;
}
.fw-5 {
    font-weight: 500;
}
.fw-4 {
    font-weight: 400;
}
.clr-blk {
    color: #222
}
.clr-gray-900 {
    color: #101828
}


.p-datatable-wrapper {
    background: white;
}

.clr-gray-3 {
    color: #828282
}

.clr-navy-light {
    color: #315975;
    opacity: 0.1;
}

.bg-navy-light {
    background: #31597520;

}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #315975;
    background: #EEF2FF;
}
.p-datepicker-title .p-datepicker-month {
    color:#315975;
    font-weight: bold;
}
.p-datepicker-title .p-datepicker-year {
    color:#315975
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #315975;
    background: #31597510;
    font-weight: bold;
}
.big-number-navy {
    color: var(--Primary, #222);
    leading-trim: both;
    text-edge: cap;

    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    /* 100% */
}

.box-shadow-one {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.clr-delete {
    color: #EE5724;
    
}

.icon-layout {
    width: 44px;
    height: 37.923px;
    flex-shrink: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.admin-card {
    padding: 20px;
    height: 180px;
    width: 100%;
    flex-direction: column;
    background: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important
}
.text-link{
    text-decoration: underline !important;
}
.text-none{
    text-decoration: none !important;
}

.hover-navy {
    
    color: var(--Navy, #315975);
    background: white;
}
.hover-navy:hover{
    background: var(--Navy, #315975) !important;
    color: white !important;
}

.status-tag-blue {
    background: rgba(189, 209, 247, 0.50);
    color: #5B8FF7;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}
.status-tag-green {
    background: rgba(41, 199, 111, 0.10);
    color: #209F00;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}

.status-tag-red {
    background: rgba(238, 87, 36, 0.10);
    color: #EE5724;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}

.status-tag-orange {
    background: rgba(249, 223, 127, 0.20);
    color: #EB961D;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}

.status-tag-gray {
    background: rgba(105, 110, 121, 0.10);
    color: #696E79;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}
.status-tag-navy {
    background: #31597520;
    color: #315975;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}
.status-tag-yellow {
    background:  #FFEEAE;
    color: #FFD22E;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}
 .hover-red {
    
    color: #EE5724;
    background: white;
}
.hover-red:hover{
    background: #EE5724 !important;
    color: white !important;
}
/* Media query for small screens (phones) */
@media only screen and (max-width: 767px) {
  /* Styles for small screens */
  .admin-card {
    height: 230px;
}
}
/* Media query for medium screens (tablets) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .admin-card {
        height: 230px;
    }
  }
  
  /* Media query for large screens (desktops) */
  @media only screen and (min-width: 1024px) {
    .admin-card {
        height: 230px;
    }
  }
  .mandatory{
    color:red
  }
  .submission-display-card-container {
    justify-content: space-between;
  }
  
  .submission-display-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    flex: 1 1 12%;
    display: flex;

    align-items: center;
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
    height:67px;
  }
  .card-label-1{

font-size: 14px;
font-weight: 400;

text-align: left;

  }
  .card-number-1{
color: #10182890;

font-size: 24px;
font-weight: 700;
padding:0px 0.5rem;
text-align: left;

  }
  
  @media screen and (max-width: 768px) {
    .submission-display-card {
      flex: 1 1 45%; /* Adjust the width for smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .submission-display-card {
      flex: 1 1 100%; /* Adjust the width for very small screens */
    }
  }
 .report-display-card-container {
    height: 130px;
    border-radius: 4px 4px 0px 4px;
    font-weight: bold;
    font-size: 16px;
  }
  .section-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
    padding: 10px;
    border-bottom: 2px solid #ccc;
}

.scrollable-content {
    max-height: 300px;
    overflow-y: auto;
}

.readmore {
    margin:10px;    
    color: #0e0e0e95;
    font-size: 15px;
    text-align: justify;
}
.p-accordion-header.currentrp a{
    background:#31597530 !important;
    color:#315975 !important;
}
.close-icon {
    font-size: 16px;
    background: #315975;
    width:30px;
    height:30px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.custom-upload-image .p-button{
padding: 0;
    background:none;
    border: none;
    
    color: #315975;
 
}
.custom-upload-image-button {
 font-weight: 700;
    padding:0.75rem 1.25rem;
    border-radius: 6px;
    background:none;
    border: 2px solid #315975;
    font-size: 1rem;
    width: 180px ;
    transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s';
    color: #315975;
}
.custom-upload-image  .p-button:not(button):not(a):not(.p-disabled):hover {
    background: none;
    border: none;
    color: #315975;
}
.custom-upload-image .p-button:focus {
    box-shadow: none;
}

:has(> .parent-full-width) {
    width: 100% !important;
  }
  .p-accordion-header.w-full a{
    background:#31597530 !important;
    color:#315975 !important;
}
.custom-datatable .p-datatable-wrapper {
    height: 70vh !important;
  }
.datatable-td-bottom {
    border-bottom: 3px solid #315975 !important;
}
.remove-round-edge button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected{
border:none !important
}